<div *ngIf="wsLength!==0" class="container mt-4">
    <div class="row justify-content-center text-center" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"
         data-aos-delay="200"
         data-aos-duration="1000" data-aos-easing="bounce">
        <div class="title col-md-9 mb-3"><span class="green">{{ wsLength }} tracks</span> to choose from!</div>
    </div>
    <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000"
         data-aos-easing="bounce">
        <div *ngFor="let workshop of workshops" class="col-sm-12 col-lg-6 col-xl-4 my-3">
            <div class="card h-100 d-flex flex-column mx-3">
                <img [src]="workshop.image.link" class="card-img-top"/>
                <div class="card-body d-flex flex-column">
                    <h5 class="card-title centered bold">{{ workshop.name }}</h5>
                    <p class="card-text">{{ workshop.shortDescription }}</p>
                    <div class="mt-auto text-center">
                        <app-cta-arrow goto="{{workshop.slug}}" label="See Details"></app-cta-arrow>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

