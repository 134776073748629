import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-cta-arrow',
    template: `
        <a [target]="target" [href]="goto" class="button-arrow">
            <span class="green">{{ label }}</span>
            <img src="assets/images/VectorRight.svg" class="vector-right"/>
        </a>
    `,
    styleUrls: ['./cta-arrow.component.scss'],
    inputs: ['label', 'goto'],
})
export class CtaArrowComponent {
    @Input() label: string;
    @Input() goto: string;
    @Input() target = '_self';
}
