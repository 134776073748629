<div class="container mb-4">
    <ngb-carousel #carousel [showNavigationIndicators]="true" interval="2500">
        <ng-template *ngFor="let testimonial of testimonials; index as i" class="col-4" data-aos="fade-up"
                     data-aos-delay="400"
                     data-aos-duration="1000" data-aos-easing="bounce" ngbSlide>
            <div class="row flex justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="300"
                 data-aos-duration="1000"
                 data-aos-easing="bounce">
                <div class="col mt-lg-3" id="testimonial-content-{{i}}">
                    <img class="img-fluid" src="assets/images/quote.svg">
                    <h3 class="fw-bold fst-italic text-start px-1 testimonial-text">
                        {{ testimonial.message }}
                    </h3>
                    <p>
                        {{ testimonial.name }}, {{ testimonial.country }}
                    </p>
                </div>
                <div class="col-4">
                    <img [src]="testimonial.image.link"
                         class="rounded-circle img-thumbnail" style="max-height: 200px">
                </div>
            </div>

        </ng-template>
    </ngb-carousel>
</div>

