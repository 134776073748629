<div class="container-fluid py-3" id="footer">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <p class="m-0">STILL HAVE QUESTIONS?</p>
                <h3 class="fw-bold m-0">
                    <a routerLink="/faq">Check out the FAQ</a>
                </h3>
            </div>
            <div class="col-12 col-md-6 col-lg-4 d-flex flex-column mt-4 mt-md-0">
                <span class="fw-bold gray">Useful Links</span>
                <a href="/p/privacy-policy">Privacy Policy</a>
            </div>
            <div class="d-flex flex-column col-12 col-md-6 col-lg-4 mt-4 mt-md-0 align-items-center">
                <span class="fw-bold gray">Contact</span>
                <a href="mailto:contact@iswint.ro">contact&#64;iswint.ro</a>
                <div class="mt-1">
                    <a class="me-2" href="https://www.instagram.com/iswint.ro" target="_blank">
                        <img height="30" ngSrc="../../../assets/images/logos/instagram.svg" width="30">
                    </a>
                    <a class="me-2" href="https://facebook.com/iswint.ro" target="_blank">
                        <img height="30" ngSrc="../../../assets/images/logos/facebook.svg" width="30">
                    </a>
                    <a class="me-2" href="https://www.tiktok.com/@iswint.ro" target="_blank">
                        <img height="30" ngSrc="../../../assets/images/logos/tiktok.svg" width="30">
                    </a>
                    <a class="me-2" href="https://twitter.com/iswint" target="_blank">
                        <img height="30" ngSrc="../../../assets/images/logos/x.svg" width="30">
                    </a>
                </div>
                <small class="mt-4">Copyright © Liga AC 1990 - {{ year }}</small>
            </div>
        </div>
    </div>
</div>
