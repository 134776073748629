import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class HeaderMenuHeightService {
    private height: any;

    public get getHeightPx() {
        return this.height + 'px';
    }

    public get defaultHeaderHeight() {
        return 66;
    }

    public getHeight() {
        return this.height;
    }

    public setHeight(value: any) {
        this.height = value;
    }
}
