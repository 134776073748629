import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {

    @Input() blog: any;

    private monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
    ];

    constructor() {
    }

    ngOnInit() {
    }

// <!-- //Published at 17:36 on 26th of July 2020 -->

    public parsDate(value: Date): string {
        return value.toLocaleTimeString('en-US',
            {hour: '2-digit', minute: '2-digit'}) +
            ' on ' +
            value.getDate() + this.getOrdinal(value.getDate()) +
            ' of ' +
            this.monthNames[value.getMonth()] +
            ' ' +
            value.getFullYear();
    }

    private getOrdinal(d) {
        if (d > 3 && d < 21) {
            return 'th';
        }
        switch (d % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }
}
