import { Component, OnInit } from '@angular/core';
import { HeaderMenuHeightService } from '../../../../services/header-menu-height.service';
import { ApiService } from '../../../../services/api.service';
import { PartnerModel } from '../../../../models/partner-model';

@Component({
    selector: 'app-apply-now-section',
    templateUrl: './apply-now-section.component.html',
    styleUrls: ['./apply-now-section.component.scss'],
})
export class ApplyNowSectionComponent implements OnInit {

    public showRegistration;
    public canEditVisa;
    public partners: PartnerModel[];
    public lenPartners: number;

    constructor(private apiService: ApiService, public heightService: HeaderMenuHeightService) {
    }

    public async ngOnInit() {
        this.showRegistration = await this.apiService
            .getSetting('showRegistrations', 'homePage');
        this.canEditVisa = await this.apiService
            .getSetting('canEditVisa');
        await this.apiService.getAllPartners().then(partners => {
            this.partners = partners.filter(partner => partner.name !== 'Parlamentul European');
        });
        this.lenPartners = this.partners.length;
    }
}
