<a [routerLink]="blog.slug">
    <div class="w-full blog-post">
        <div class="d-flex flex-column cont">
            <div *ngIf="blog.image" class="aspect-ratio-box">
                <img [lazyLoad]="blog.image.path" class="aspect-ratio-box-inside"/>
            </div>
            <div class="p-4">
                <h3 class="fw-bold">{{ blog.title }}</h3>
                <p>{{ blog.shortDescription }}</p>
            </div>
        </div>
    </div>
</a>
