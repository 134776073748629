<div id="partners"></div>
<div *ngIf="hasPartners" class="component inner-shadow-top">
    <div class="container">
        <ng-container *ngFor="let partnerType of partnerTypes">
            <div *ngIf="partnerType.display" class="row">
                <div class="col-12 mt-5 centered type-title">
                    {{ partnerType.name }}
                </div>
                <ng-container [ngSwitch]="partnerType.display">
                    <ng-container *ngSwitchCase="'tiers'">
                        <ng-container *ngFor="let tier of partnerType.tiers">
                            <ng-template
                                    [ngTemplateOutletContext]="{partners: tier.partners, title: tier.name, logoSize: tier.logoSize}"
                                    [ngTemplateOutlet]="partnersList"></ng-template>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'partners'">
                        <ng-template
                                [ngTemplateOutletContext]="{partners: partnerType.partners, logoSize: partnerType.logoSize}"
                                [ngTemplateOutlet]="partnersList"></ng-template>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

    </div>

    <ng-template #partnersList let-logoSize="logoSize" let-partners='partners' let-title="title">
        <div *ngIf="title" [className]="'col-12 mt-4 centered title-' + logoSize">
            {{ title }}
        </div>
        <div class="col-12 centered flex-wrap mb-5">
            <div *ngFor="let partner of partners" [className]="'size-' + logoSize">
                <div class="logo-container d-flex align-items-center">
                    <a [href]="partner.url" target="_blank"><img [src]="partner.logo.link" class="logo"/></a>
                </div>
            </div>
        </div>
    </ng-template>
</div>
