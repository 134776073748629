import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {WorkshopModel} from '../../../../models/workshops.model';

@Component({
    selector: 'app-workshops-list-section',
    templateUrl: './workshops-list-section.component.html',
    styleUrls: ['./workshops-list-section.component.scss']
})
export class WorkshopsListSectionComponent implements OnInit {

    public workshops: WorkshopModel[];
    public wsLength;

    constructor(private apiService: ApiService) {
    }

    async ngOnInit() {
        this.workshops = await this.apiService
            .getWorkshops();

        this.wsLength = this.workshops.length;
    }

}
