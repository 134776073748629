import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'eventDate',
})
export class EventDatePipe implements PipeTransform {

    transform(date: Date | string, format: string = 'dd MMMM / hh:mm a\''): string {
        return new DatePipe('en-US').transform(date, format);
    }

}
