<div class="background-transition">
    <div class="container">
        <nav class="navbar navbar-expand-md justify-content-between col">
            <a (click)="toggleMenuOpen()" [routerLink]="'/'" class="navbar-brand">
                <img class="header-menu-logo padding-logo-right mb-2"
                     src="/assets/images/logos/iswint.svg"/>

            </a>
            <!--            <a *ngIf="ep" [href]="ep.url" class="navbar-brand">-->
            <!--                <img [src]="ep.logo.link" class="partners-menu-logo">-->
            <!--            </a>-->
            <button
                    (click)="toggleMenuOpen()"
                    [attr.aria-expanded]="!isCollapsed"
                    aria-controls="navbarSupportedContent"
                    aria-label="Toggle navigation"
                    class="navbar-toggler"
                    data-bs-target="#navbarSupportedContent"
                    data-bs-toggle="hidden"
                    type="button"
            >
                <svg class="svg-icon" viewBox="0 0 20 20">
                    <path
                            d="M3.314,4.8h13.372c0.41,0,0.743-0.333,0.743-0.743c0-0.41-0.333-0.743-0.743-0.743H3.314
               c-0.41,0-0.743,0.333-0.743,0.743C2.571,4.467,2.904,4.8,3.314,4.8z M16.686,15.2H3.314c-0.41,0-0.743,0.333-0.743,0.743
               s0.333,0.743,0.743,0.743h13.372c0.41,0,0.743-0.333,0.743-0.743S17.096,15.2,16.686,15.2z M16.686,9.257H3.314
               c-0.41,0-0.743,0.333-0.743,0.743s0.333,0.743,0.743,0.743h13.372c0.41,0,0.743-0.333,0.743-0.743S17.096,9.257,16.686,9.257z"
                            fill="#000000"
                    ></path>
                </svg>
            </button>
            <div
                    [ngClass]="{ overlay: !isCollapsed }"
                    class="hidden navbar-collapse justify-content-end me-lg-5"
            >
                <div>
                    <div class="d-ld-none"></div>
                    <ul class="navbar-nav col align-self-center font-size-mobile">
                        <li
                                *ngFor="let item of menuItems; index as i"
                                class="nav-item me-5 me-sm-3"
                        >
              <span
                      (click)="setSubMenu(i)"
                      *ngIf="hasChildren(item)"
                      class="nav-link"
              >
                {{ item.name }}
                  <img
                          alt="arrow"
                          src="../../../assets/images/drop-down-arrow.svg"
                  />
              </span>
                            <a *ngIf="!hasChildren(item)" [href]="item.link" class="nav-link">
                                {{ item.name }}
                            </a>
                            <ul
                                    *ngIf="hasChildren(item)"
                                    [ngClass]="{ 'drop navbar-nav': true, 'd-flex': subMenu === i }"
                            >
                                <li *ngFor="let i of item.childs">
                                    <ng-container *ngIf="(i.type != 'ExternalLink'); else elseBlock">
                                        <a
                                                *ngIf="i.type != 'ScrollTo'"
                                                [routerLink]="i.link"
                                                class="drop-link align-items-center"
                                        >
                                            <div class="d-flex flex-column align-items-start">
                                                <div class="d-flex align-items-center justify-content-center mb-1">
                                                    <img *ngIf="item.name === 'Tracks'" [src]="i.icon"
                                                         class="workshops-icon me-2">
                                                    <div> {{ i.name.split("|")[0] }} </div>
                                                </div>
                                                <div *ngIf="i.name.split('|')[1]" class="little">
                                                    {{ i.name.split("|")[1] }}
                                                </div>
                                            </div>
                                        </a>
                                    </ng-container>

                                    <ng-template #elseBlock>
                                        <a *ngIf="i.type !== 'ScrollTo'"
                                           [href]="i.link"
                                           class="drop-link align-items-center"
                                           target="_blank"
                                        >
                                            <div>
                                                <div> {{ i.name.split("|")[0] }} </div>
                                                <div *ngIf="i.name.split('|')[1]" class="little">
                                                    {{ i.name.split("|")[1] }}
                                                </div>
                                            </div>
                                        </a>
                                    </ng-template>

                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</div>
