import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
    selector: 'app-blogs-page',
    templateUrl: './blogs-page.component.html',
    styleUrls: ['./blogs-page.component.scss'],
})
export class BlogsPageComponent implements OnInit {

    public articles: any;
    public firstArticle: any;

    constructor(private api: ApiService) {
        this.api.getArticles().subscribe((data?: Array<any>) => {
            this.firstArticle = data.shift();
            this.articles = data;
        });
    }

    async ngOnInit() {
    }
}
