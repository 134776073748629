import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-landing-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {

    constructor(private route: ActivatedRoute) {
        const code = route.snapshot.queryParams.promoter;
        if (code) {
            localStorage.setItem('promoterCode', code);
        }
    }
}
