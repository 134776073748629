import {APP_ID, ErrorHandler, Injectable, NgModule} from '@angular/core';

import {AppModule} from './app.module';
import {AppComponent} from './app.component';
import {SentryWrapper} from './services/sentry-wrapper.common.service';
import {SentryWrapperBrowser} from './services/sentry-wrapper.browser.service';
import {BrowserModule} from '@angular/platform-browser';
import {TransferHttpCacheModule} from '@nguniversal/common';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor(private sentry: SentryWrapper) {
    }

    handleError(error: any) {
        this.sentry.captureException(error.originalError || error);
        throw error;
    }
}

@NgModule({
    imports: [
        AppModule,
        BrowserModule,
        TransferHttpCacheModule
    ],
    bootstrap: [AppComponent],
    providers: [
        {provide: APP_ID, useValue: 'iswint-website'},
        {provide: SentryWrapper, useClass: SentryWrapperBrowser},
        {provide: ErrorHandler, useClass: SentryErrorHandler},
    ],
})
export class AppBrowserModule {
    constructor(private sentry: SentryWrapper) {
        sentry.init();
    }
}
