<div class="container full-height-vh mt-4">
    <div class="row">
        <div class="col-12 my-1">
            <app-blog
                    *ngIf="firstArticle"
                    [blog]="firstArticle"
                    class="w-full"
            ></app-blog>
        </div>
    </div>
    <div class="row">
        <div *ngFor="let article of articles" class="col-12 col-md-6 my-1">
            <app-blog [blog]="article" class="w-full"></app-blog>
        </div>
    </div>
</div>
