import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePageComponent} from './pages/home-page/home-page/home-page.component';
import {FaqPageComponent} from './pages/faq-page/faq-page.component';
import {PageResolver} from './resolvers/page.resolver';
import {BlogsPageComponent} from './pages/blogs-page/blogs-page.component';
import {BasePageComponent} from './pages/base-page/base-page.component';
import {EventsPageComponent} from './pages/events-page/events-page.component';

const routes: Routes = [
    {
        path: '',
        component: HomePageComponent,
        pathMatch: 'full',
        resolve: {entity: PageResolver},
        data: {ogMetaType: 'home'},
    },
    {
        path: 'events',
        component: EventsPageComponent,
        pathMatch: 'full',
    },
    {
        path: 'p/:slug',
        component: BasePageComponent,
        resolve: {entity: PageResolver},
    },
    {
        path: 'w/:slug',
        component: BasePageComponent,
        resolve: {entity: PageResolver},
    },
    {
        path: 'blog',
        component: BlogsPageComponent,
        resolve: {entity: PageResolver},
        data: {ogMetaType: 'blog'},
    },
    {
        path: 'blog/guide',
        resolve: {entity: PageResolver},
        redirectTo: 'p/guide',
    },
    {
        path: 'blog/:slug',
        component: BasePageComponent,
        resolve: {entity: PageResolver},
        data: {ogMetaType: 'blog-post'},
    },
    {
        path: 'faq',
        component: FaqPageComponent,
        resolve: {entity: PageResolver},
        data: {
            noColoredBanner: true,
            ogMetaType: 'faq',
        },
    },
    {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking'}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
