import { Component, Input } from '@angular/core';
import { EventModel } from '../../../models/event.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-event-card',
    templateUrl: './event-card.component.html',
    styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent {
    @Input() event: EventModel;

    constructor(private router: Router) {
    }

    public setBackgroundImage(): string {
        return 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(' + this.event.image.link + ')';
    }

    public styleTypeIdentifier(): string {
        if (this.event.type === 'Public') {
            return '#29712A';
        } else if (this.event.type === 'PublicWithRegistration') {
            return '#FFDF00';
        }
        return '#7C1BC1';
    }

    public async goToEventPage(): Promise<void> {
        await this.router.navigate([this.event.link]);
    }
}
