import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer, } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ConstantsService {
    private readonly _apiUrl: string = '/api';

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        if (isPlatformServer(platformId)) {
            const backendUrl = process.env.BACKEND_URL || '';
            this._apiUrl = backendUrl + this._apiUrl;
        }
    }

    public get api() {
        return this._apiUrl;
    }
}
