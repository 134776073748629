import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { OgMetaModel } from '../models/og-meta-model';
import { MetaTagsTitleService } from '../services/meta-tags-title.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';

@Injectable()
export class PageResolver  {

    private metas: { [key: string]: OgMetaModel } = {
        'default': {
            title: 'ISWinT',
            type: 'website',
            description: 'Applications for the 2020 edition of ISWinT are now open! Apply now!',
            image: '/assets/images/og/OG-Apply.png',
        },
        '/p/about-us': {
            title: 'About us - ISWinT',
            type: 'website',
            description: 'Here you can find more details about the organizers of ISWinT.',
            image: '/assets/images/og/OG-Default.png',
        },
        '/blog': {
            title: 'The ISWinT Blog',
            type: 'website',
            description: `Find out what's new in ISWinT!`,
            image: '/assets/images/og/OG-Default.png',
        },
    };

    constructor(protected metaTags: MetaTagsTitleService,
                protected apiService: ApiService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.loadMetaData(route.data.ogMetaType);
    }

    private async loadMetaData(id: string): Promise<any> {
        let metaData = this.metas.default;
        try {
            const data = await firstValueFrom(this.apiService.getMetaData(id));
            metaData = {
                title: data.title || metaData.title,
                type: data.type || metaData.type,
                description: data.description || metaData.description,
                image: data.image || metaData.image,
            };
        } catch (e) {
        }
        this.metaTags.updateTags(metaData);
    }
}
