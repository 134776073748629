<h1 class="mt-4">Learning and <span>making friends worldwide</span>!</h1>
<p>
    An international student festival where the nights will turn into mornings and the friends will
    turn into
    family!
</p>
<p>
    A unique experience where we combine educational workshops with meeting new people while having
    fun and living epic moments.
</p>
<!-- <small>Registrations will open on <b>8th of January 00:00</b> UTC +2:00</small> -->
