import { Component } from '@angular/core';

@Component({
  selector: 'app-aftermovie-video',
  templateUrl: './aftermovie-video.component.html',
  styleUrls: ['./aftermovie-video.component.scss']
})
export class AftermovieVideoComponent {

}
