import { Injectable } from '@angular/core';

import * as urlParse from 'url-parse';

interface IHashTable<T> {
    [key: string]: T;
}

export declare class UrlObject {
    public readonly protocol: string;
    public readonly slashes: boolean;
    public readonly auth: string;
    public readonly username: string;
    public readonly password: string;
    public readonly host: string;
    public readonly hostname: string;
    public readonly port: number;
    public readonly pathname: string;
    public readonly query: IHashTable<string>;
    public readonly hash: string;
    public readonly href: string;
    public readonly origin: string;

    public set(key: string, value: string): void;

    public toString(): string;
}

@Injectable()
export class UrlParse {
    public parse(url: string): UrlObject {
        return urlParse(url, {}, true);
    }
}
