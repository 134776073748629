<div class="d-flex w-auto">
    <div class="bg-light-green rounded-pill green py-2 px-2 sm-small">
        <span class="bg-green rounded-pill yellow py-1 px-2 font-weight-bold">WHEN?</span>
        <span class="mx-2">
            FROM 22.07.2024 TO 31.07.2024
        </span>
    </div>
</div>

<div class="d-flex w-auto">
    <div class="bg-light-green rounded-pill green py-2 px-2 sm-small">
        <span class="bg-yellow rounded-pill green py-1 px-2 font-weight-bold">
            <span class="mx-2">
                CONTRIBUTION
            </span>
        </span>
        <span class="mx-1 price">
            130€ + ADDITIONAL FEES
        </span>
        <ng-template #content>
            <ul>
                <li>Accommodation</li>
                <li>Food</li>
                <li>Social events</li>
                <li>2-Day Trip</li>
                <li>Workshops and other activities</li>
                <li>Transportation within Timișoara</li>
            </ul>
        </ng-template>
    </div>
</div>
<div class="d-flex w-auto">
    <div class="bg-light-green rounded-pill green py-2 px-2 sm-small">
        <span class="bg-green rounded-pill yellow py-1 px-2 font-weight-bold">CONTRIBUTION for EUDRES Partners</span>
        <span class="mx-2">
            100€
        </span>
    </div>
</div>
<div class="d-flex w-auto font">
    <button [ngbPopover]="content" class="btn" type="button">
        <u class="green">
            <div class="bold">What we provide for our participants</div>
        </u>
    </button>
</div>
