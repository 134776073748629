import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';

@Component({
    selector: 'app-join-us-section',
    templateUrl: './join-us-section.component.html',
    styleUrls: ['./join-us-section.component.scss']
})
export class JoinUsSectionComponent implements OnInit {
    public showRegistration;
    public canEditVisa;
    constructor(private apiService: ApiService) {
    }

    async ngOnInit() {
        this.showRegistration = await this.apiService
            .getSetting('showRegistrations', 'homePage');
        this.canEditVisa = await this.apiService
            .getSetting('canEditVisa');
    }
}
