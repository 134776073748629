import { Component } from '@angular/core';

@Component({
  selector: 'app-short-description',
  templateUrl: './short-description.component.html',
  styleUrls: ['./short-description.component.scss']
})
export class ShortDescriptionComponent {

}
