import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-base-page-content',
    templateUrl: './base-page-content.component.html',
    styleUrls: ['./base-page-content.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class BasePageContentComponent {
    @Input() innerHtmlContent!: string | DocumentFragment;
}
