import {Component} from '@angular/core';
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-participation-fee',
    templateUrl: './participation-fee.component.html',
    styleUrls: ['./participation-fee.component.scss']
})
export class ParticipationFeeComponent {

}
