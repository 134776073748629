import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {NavigationEnd, Router, RoutesRecognized} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {MenuItemModel} from '../../models/menu-item.model';
import {PartnerModel} from '../../models/partner-model';

@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {

    public menuItems: MenuItemModel[];
    public isCollapsed = true;
    public noColoredBanner = false;
    public subMenu = -1;
    public ep: PartnerModel;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private router: Router,
                private api: ApiService) {
        this.populateMenu();
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.isCollapsed = true;
            }
            if (event instanceof RoutesRecognized && event.state.root.firstChild.data) {
                this.noColoredBanner = event.state.root.firstChild.data.noColoredBanner === true;
            }
        });
    }

    async ngOnInit() {
        await this.api.getAllPartners().then(partners => {
            partners.forEach(partner => {
                if (partner.name === 'Parlamentul European') {
                    this.ep = partner;
                }
            });
        });
    }

    public setSubMenu(index) {
        if (window.innerWidth >= 768) {
            return;
        }
        if (index === this.subMenu) {
            this.subMenu = -1;
        } else {
            this.subMenu = index;
        }
    }

    toggleMenuOpen() {
        this.isCollapsed = !this.isCollapsed;
        this.isCollapsed ? this.toggleBodyClass('stopScroll', true) : this.toggleBodyClass('stopScroll', false);
    }

    public hasChildren(item: MenuItemModel) {
        if (item.childs === undefined) {
            return false;
        }
        return item.childs.some(x => true);
    }

    private toggleBodyClass(className: string, add: boolean): void {
        if (isPlatformBrowser(this.platformId)) {
            const body = document.getElementsByTagName('body')[0];
            if (add) {
                body.classList.add(className);
            } else {
                body.classList.remove(className);
            }
        }
    }

    private populateMenu() {
        this.api.getMenuItems().subscribe(res => {
            this.menuItems = res;
            const menuItemTracks = this.menuItems.find(m => m.name === 'Tracks');
            if (menuItemTracks) {
                menuItemTracks.childs.forEach(m => {
                    m.icon = 'assets/images/icons/' + m.link.slice(3) + '.svg';
                });
            }
        });
    }
}
