import {
    Component,
    ElementRef,
    HostListener,
    Inject,
    PLATFORM_ID,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {FaqCategory} from '../../models/faq-model';
import {HeaderMenuHeightService} from '../../services/header-menu-height.service';

@Component({
    selector: 'app-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss'],
})
export class FaqPageComponent {

    @ViewChildren('faqCategoryElements')
    public faqCategoryElements !: QueryList<ElementRef>;
    @ViewChild('banner', {static: true})
    public banner: ElementRef;
    @ViewChild('titleElement', {static: true})
    public titleElement: ElementRef;
    public faqCategories: FaqCategory[];
    public selectedIndex: any;

    constructor(private apiService: ApiService, @Inject(PLATFORM_ID) private platformId: Object, private hms: HeaderMenuHeightService) {
        this.loadFaqs();
    }

    public selectCategory(index: number) {
        const element = this.faqCategoryElements.toArray()[index];
        if (element) {
            const top = element.nativeElement.offsetTop;
            window.scrollTo({
                top: top + 80,
                behavior: 'smooth',
            });
        }
        for (const f of this.faqCategories) {
            if (f.selected) {
                f.selected = false;
            }
        }
        this.faqCategories[index].selected = true;
    }

    private loadFaqs() {
        this.apiService.getFaqs().subscribe(data => {
            this.faqCategories = data;
            if (!this.faqCategories.length) {
                return;
            }
            this.faqCategories[0].selected = true;
            this.selectedIndex = 0;
        }, error1 => {
        });
    }
}
