import {Component, Inject, OnInit} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {PLATFORM_ID} from "@angular/core";
import * as AOS from 'aos';
import {isPlatformBrowser} from "@angular/common";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    title = 'iswint-website';

    constructor(private meta: Meta, @Inject(PLATFORM_ID) private platformId: Object) {
    }

    public ngOnInit(): void {
        this.setMobileBrowserBarColors();
        if (isPlatformBrowser(this.platformId)) {
            AOS.init();
        }
    }


    private setMobileBrowserBarColors() {
        const color = '#00092D';
        this.meta.updateTag({name: 'theme-color', content: color});
        this.meta.updateTag({name: 'sapplication-navbutton-color', content: color});
        this.meta.updateTag({name: 'apple-mobile-web-app-status-bar-style', content: color});
    }
}
