<div class="container-fluid overflow-y-hidden padding">
    <div class="position-relative">
        <img src="assets/images/ISWinT-World-Map.svg" width="100%"/>
        <div class="abs">
            <div
                    class="d-flex h-100 align-items-center justify-content-center flex-column"
            >
                <p class="gray text-center fw-bold m-0" data-aos="fade-up" data-aos-delay="200"
                   data-aos-duration="1000" data-aos-easing="bounce">
                    Based in Romania, in a city called
                </p>
                <h1 class="romania fw-bold mb-4" data-aos="fade-up" data-aos-delay="300"
                    data-aos-duration="1000" data-aos-easing="bounce">TIMIȘOARA</h1>
                <app-cta-arrow data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-easing="bounce"
                               goto="/p/venue" label="See the Venue"></app-cta-arrow>
            </div>
        </div>
    </div>
</div>
