import * as Sentry from '@sentry/browser';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SentryWrapper } from './sentry-wrapper.common.service';
import { REQUEST } from '../../express.tokens';

@Injectable()
export class SentryWrapperBrowser extends SentryWrapper {
    constructor(
        @Optional() @Inject(REQUEST) request: any,
        @Inject(PLATFORM_ID) platformId: Object
    ) {
        super(request, platformId, Sentry);
    }

    protected shouldSendEvent() {
        return isPlatformBrowser(this.platformId);
    }
}
