import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {environment} from '@env/environment';
import {AppBrowserModule} from './app/app.browser.module';


if (environment.production) {
    enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppBrowserModule).catch(err => console.error(err));
