import {Component, OnInit, ViewChild} from '@angular/core';
import {TestimonialModel} from '../../../../models/testimonial-model';
import {NgbCarousel, NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from "../../../../services/api.service";

@Component({
    selector: 'app-testimonials-section',
    templateUrl: './testimonials-section.component.html',
    styleUrls: ['./testimonials-section.component.scss'],
    providers: [NgbCarouselConfig],
})
export class TestimonialsSectionComponent {

    @ViewChild('carousel', {static: true}) carousel: NgbCarousel;
    public testimonials: TestimonialModel[];

    constructor(private apiService: ApiService,
                private config: NgbCarouselConfig) {
        config.showNavigationIndicators = false;
        config.showNavigationArrows = false;
    }

    async ngOnInit() {
        this.testimonials = await this.apiService.getTestimonials();

    }
}
