import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { EventModel } from '../../models/event.model';
import { HeaderMenuHeightService } from '../../services/header-menu-height.service';

@Component({
    selector: 'app-events-page',
    templateUrl: './events-page.component.html',
    styleUrls: ['./events-page.component.scss'],
})
export class EventsPageComponent implements OnInit {
    events: EventModel[];

    constructor(private readonly apiService: ApiService,
                public heightService: HeaderMenuHeightService) {
    }

    async ngOnInit(): Promise<void> {
        await this.apiService.getEvents().then(events => this.events = events);
    }

    public getNextEvent(): EventModel {
        const currentDate = new Date();
        return this.events.find(event => new Date(event.date) >= currentDate);
    }

    public getCardArrangement(index: number): string {
        return index % 2 === 1 ? 'flex-end' : 'flex-start';
    }
}
