import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {MetaTagsTitleService} from '../../services/meta-tags-title.service';


@Component({
    selector: 'app-base-page',
    templateUrl: './base-page.component.html',
    styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent implements OnInit {

    public page: any;
    public loading: boolean;
    public icon = '';

    constructor(protected route: ActivatedRoute, private api: ApiService, private meta: MetaTagsTitleService) {
        this.loading = true;
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            const slug = params.slug;
            this.loading = true;
            if (this.route.snapshot.url[0].path === 'blog') {
                this.getPage('getArticle', slug);
            } else {
                this.getPage('getPage', slug);
            }
        });
    }

    async getPage(type: string, slug: string) {
        const page = await this.api[type](slug);
        this.loading = false;
        this.page = page;
        if (this.route.snapshot.url[0].path === 'w') {
            this.icon = 'assets/images/icons/' + this.page.slug + '.svg';
        }
        this.meta.updateTags({
            title: this.page.title,
            type: 'page',
            description: this.page.shortDescription,
            image: '/assets/images/og/OG-Default.png' || this.page.image.link,
        });
    }
}
