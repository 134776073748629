<div class="container" id="banner">
    <div class="content-wrapper mt-4">
        <h2 #titleElement>
            Frequently Asked Questions
        </h2>
        <div class="row mt-4">
            <div class="col-12 col-lg-4 position-relative">
                <div class="col-12 col-lg-3 category-menu-container">
                    <ng-container *ngFor="let faqCategory of faqCategories; let i = index">
                        <div
                                (click)="selectCategory(i)"
                                *ngIf="faqCategory.name"
                                [ngClass]="{ highlight: faqCategory.selected }"
                                class="category-name"

                        >
                            {{ faqCategory.name }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 col-md-8 mt-4 mt-md-0">
                <ng-container *ngFor="let faqCategory of faqCategories; let i = index">
                    <div *ngIf="faqCategory.selected">
                        <h3
                            #faqCategoryElements
                            class="mb-4"
                        >
                            {{ faqCategory.name }}
                        </h3>
                        <div *ngFor="let faq of faqCategory.faqs" class="question mb-4">
                            <div class="fw-bold mb-0 h4 mb-2"
                            >
                                {{ faq.question }}
                            </div>
                            <div [innerHTML]="faq.answer" class="question-body"
                            ></div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
