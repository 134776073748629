<div (click)="goToEventPage()"
     [ngStyle]="{'background-image': setBackgroundImage()}"
     class="card-container d-inline-flex flex-row"
>
    <div [ngStyle]="{'background-color': styleTypeIdentifier()}" class="identifier"></div>
    <div class="content mx-4 d-flex flex-column">
        <div class="content-row content-top d-flex flex-row">
            <div class="title">{{event.title}}</div>
            <div class="date">{{event.date | eventDate}}</div>
        </div>
        <div class="content-row content-bottom d-flex flex-row">
            <div>{{event.shortDescription}}</div>
        </div>
    </div>
</div>
