import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-cta',
    template: `
        <button class="cta">
            {{ label }}
        </button>
    `,
    styleUrls: [`./cta.component.scss`],
    inputs: ['label'],
})
export class CtaComponent {
    @Input() label: string;
}
