import {Component, Input} from '@angular/core';
import {ApiService} from "../../../../../services/api.service";

@Component({
    selector: 'app-apply-now-buttons',
    templateUrl: './apply-now-buttons.component.html',
    styleUrls: ['./apply-now-buttons.component.scss']
})
export class ApplyNowButtonsComponent {
    @Input() canEditVisa;

    constructor(private apiService: ApiService) {
    }

}
