import { Injectable } from '@angular/core';
import { OgMetaModel } from '../models/og-meta-model';
import { Meta, MetaDefinition } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class MetaTagsTitleService {

    constructor(private meta: Meta) {
    }

    public updateTags(ogMeta: OgMetaModel) {
        for (const metaTagName in ogMeta) {
            if (!ogMeta.hasOwnProperty(metaTagName)) {
                continue;
            }
            const metaDef: MetaDefinition = {property: 'og:' + metaTagName, content: ogMeta[metaTagName]};
            this.meta.updateTag(metaDef);
        }
    }
}
