import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {PartnerTypeModel} from '../../../../models/partner-type.model';

@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent implements OnInit {
    public partnerTypes: PartnerTypeModel[];
    size: any;
    width: number;
    height: number;

    constructor(private apiService: ApiService) {
    }

    public get hasPartners() {
        return this.partnerTypes && this.partnerTypes.length;
    }

    onChange(imageUrl: string) {
        const img = new Image();
        img.src = 'https://thepetclub.ro/blog/wp-content/uploads/2019/09/pisici-care-nu-lasa-par.jpg';
        img.onload = (e) => {
        };
    }

    async ngOnInit() {
        this.partnerTypes = await this.apiService.getPartners();
        // console.log(this.partnerTypes);
        const imgUrl = this.partnerTypes[0].partners[0].logo['link'];
        // this.onChange(imgUrl);
        for (const type of this.partnerTypes) {
            if (type) { // console.log(type.partners[0].logo);
                if (type.partners && type.partners.length) {
                    if (type.tiers && type.tiers.length) {
                        type.display = 'tiers';
                    } else {
                        type.display = 'partners';
                    }
                }
            }
        }
    }
}
