<div class="component">
    <div [ngStyle]="{'padding-top': heightService.getHeightPx}" class="container">
        <div class="content">
            <div *ngIf="events && events.length > 0" class="d-flex page-header">
                <div class="header-title d-flex flex-column col-12 col-md-6">
                    <h1 class="yellow heading"><span>What is your next </span>adventure<span>?</span></h1>
                    <div class="d-flex legend">
                        <div class="d-flex flex-row legend-indicator">
                            <div class="square" style="background-color: #29712A"></div>
                            <p>no registration needed</p>
                        </div>
                        <div class="d-flex flex-row legend-indicator">
                            <div class="square" style="background-color: #FFDF00"></div>
                            <p>registration needed</p>
                        </div>
                        <div class="d-flex flex-row legend-indicator">
                            <div class="square" style="background-color: #7C1BC1"></div>
                            <p>only for selected participants</p>
                        </div>
                    </div>
                </div>
                <app-event-card *ngIf="getNextEvent()" [event]="getNextEvent()"></app-event-card>
            </div>
            <div *ngIf="events && events.length > 0" class="cards-container d-flex row">
                <ng-container *ngFor="let event of events; let i = index">
                    <app-event-card [event]="event" [ngStyle]="{'justify-content': getCardArrangement(i)}"
                                    class="col my-3"></app-event-card>
                </ng-container>
            </div>
        </div>
    </div>
</div>
