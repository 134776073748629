<div class="component" id="top">
    <img class="bg" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"
         data-aos-easing="bounce" src="assets/images/green-background.svg">
    <img class="green-dots" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000"
         data-aos-easing="bounce" src="assets/images/green-dots.svg">
    <div [ngStyle]="{'padding-top': heightService.getHeightPx}" class="container">
        <div class="row" data-aos="fade-up" data-aos-delay="100"
             data-aos-duration="1000" data-aos-easing="bounce">
            <div class="col-12 col-lg me-lg-5">
                <app-participation-fee></app-participation-fee>
                <app-short-description></app-short-description>
                <div *ngIf="showRegistration">
                    <app-apply-now-buttons [canEditVisa]="this.canEditVisa"></app-apply-now-buttons>
                </div>
            </div>
            <div class="col-12 col-lg ms-lg-5 mt-4 position-relative centered">
                <app-aftermovie-video></app-aftermovie-video>
            </div>
        </div>
    </div>
</div>
