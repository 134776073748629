import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from '@env/environment';

declare var COMMITHASH: any;

export abstract class SentryWrapper {
    private readonly sentry: any = null;
    private readonly env: string;

    protected constructor(protected request: any, protected platformId: Object, sentry: any) {
        this.sentry = sentry;

        if (isPlatformServer(this.platformId) && request) {
            if (this.request.get('host')) {
                this.env = this.request.get('host');
            }
        } else if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
            if (window.location && window.location.hostname) {
                this.env = window.location.hostname;
            }
        }
    }

    public init(options?: any): boolean {
        if (!options) {
            options = {};
        }
        options.dsn = 'https://cb337295cb9941e8b1d79c53a3d5ca15@sentry.io/1391465';
        //options.release = COMMITHASH;
        options.environment = this.env;
        return this.wrap(() => this.sentry.init(options));
    }

    public captureException(exception: any): boolean {
        const reported = this.wrap(() => this.sentry.captureException(exception));
        if (!reported) {
            console.error(exception);
        }
        return reported;
    }

    protected shouldSendEvent() {
        return false;
    }

    protected wrap(action: () => void): boolean {
        if (environment.production && this.sentry && this.shouldSendEvent()) {
            try {
                // NOTE: "production" is any build generated by "ng build --prod"
                //  - this includes dev.practica.ligaac.ro, or even local builds with "npm run build:ssr"
                action();
                return true;
            } catch (sentryErr) {
                console.group('sentry error');
                console.error(sentryErr);
                console.groupEnd();
            }
        }

        return false;
    }
}
